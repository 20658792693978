
.main-content {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    .oss-form {
        width: 800px;
    }
}

